import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout/layout';

import { Helmet } from 'react-helmet';

const MembersData = ({ data: { allContentfulMembersATypeData: aTypeData, allContentfulMembersBTypeData: bTypeData } }) => {
    //nodes.forEach(({ orderId, header }) => console.log(orderId, header));
    const dataItemsTypeA = aTypeData.nodes.sort((a, b) => a.orderId - b.orderId);
    const dataItemsTypeB = bTypeData.nodes.sort((a, b) => a.orderId - b.orderId);
    return (
        <Layout location="members" metadata={ { title: "株式会社テストサービス", is404: true } }>
            <main className="page--members-data page u-margin-bottom-medium">
                <div class="data-flex-container data-flex-container--entry">
                    { 
                        dataItemsTypeA.map(({ orderId, header, pdfArchiveDataBundleReference: bundle }, j) => {
                            return (
                                <div className="data-entry data-container">
                                	<h3 className="data-entry__header data-container__header" key={ orderId }>{ header }</h3>
                                    { 
                                    bundle.sort((a, b) => a.orderId - b.orderId)
                                        .map((bundleItem, j) => {
                                            return (
                                                <div className="data-entry__data-bundle" key={ j }>
                                                    <h4 className="data-entry__subheader data-container__subheader"> 
                                                        { bundleItem.header }
                                                    </h4>
                                                    <div className="data-entry__data-pdf">
                                                        <a className="data-entry__data-link data-container__data-link" 
                                                        href={ bundleItem?.pdfItem?.file?.url }>
                                                            <i className="material-icons">picture_as_pdf</i>Link to pdf
                                                        </a>
                                                    </div>
                                                    <div className="data-entry__data-archive">
                                                        <a className="data-entry__data-link data-container__data-link" 
                                                        href={ bundleItem?.archiveItem?.file?.url }>
                                                            <i className="material-icons">archive</i>Link to archive
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div class="data-flex-container data-flex-container--year">
                    {
                        dataItemsTypeB.map(({ orderId, yearText, pdfArchiveDataBundleReference: bundle }, j) => {
                            return (
                                <div className="data-year data-container" key={ j }>
                                    <h3 className="data-year__header data-container__header" key={ orderId }> { yearText }</h3>
                                    {
                                        bundle.sort((a, b) => a.orderId - b.orderId)
                                            .map((bundleItem, j) => {
                                                return (
                                                    <div className="data-year__data-bundle" key={ j }>
                                                        <h4 className="data-year__subheader data-container__subheader"> 
                                                            { bundleItem.header }
                                                        </h4>
                                                        <div className="data-year__data-pdf">
                                                            <a className="data-year__data-link data-container__data-link" 
                                                            href={ bundleItem?.pdfItem?.file?.url }>
                                                                <i className="material-icons">picture_as_pdf</i>Link to pdf
                                                            </a>
                                                        </div>
                                                        <div className="data-year__data-archive">
                                                            <a className="data-year__data-link data-container__data-link" 
                                                            href={ bundleItem?.archiveItem?.file?.url }>
                                                                <i className="material-icons">archive</i>Link to archive
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </main>
            
        </Layout>
    )
}

export const query = graphql`
{
    allContentfulMembersATypeData {
        nodes {
            orderId
            header
            pdfArchiveDataBundleReference {
                pdfItem {
                    file {
                        url
                    }
                }
                archiveItem {
                    file {
                        url
                    }
                }
                header
                orderId
            }
        }
    }
    allContentfulMembersBTypeData {
        nodes {
            orderId
            yearText
            pdfArchiveDataBundleReference {
                pdfItem {
                    file {
                        url
                    }
                }
                archiveItem {
                    file {
                        url
                    }
                }
                header
            }
        }
    }
}
`;

export default MembersData;
